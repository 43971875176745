<template>
  <v-row>
    <v-col cols="10"><v-spacer /></v-col>
    <v-col cols="2">
      <div align="right">
        <v-btn
          id="menu-activator"
          variant="plain"              
        >
        {{accountName}}
        </v-btn>
        <v-menu activator="#menu-activator">
          <v-list>
            <v-list-item
              v-for="item in menuSelect"
              :key="item.key"
              :value="item.key"
              link
              :disabled="item.key === 'changePass' && adLogin"
              @click="selectItem(item)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-col>
    <v-dialog v-model="dialog" max-width="500px">
      <loading :active.sync="isLoading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
      <v-card>
        <v-card-title>
          <span class="headline">パスワード変更</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="nowPassword"
                  :maxlength="128"
                  placeholder="現在のパスワードを入力"
                  :type="passwordShowNow ? 'text' : 'password'"
                  :append-icon="passwordShowNow ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="passwordShowNow = !passwordShowNow">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="password"
                  :maxlength="128"
                  placeholder="変更後パスワードを入力"
                  :type="passwordShow ? 'text' : 'password'"
                  :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="passwordShow = !passwordShow">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="confirm"
                  :maxlength="128"
                  placeholder="確認"
                  :type="passwordShowConfirm ? 'text' : 'password'"
                  :append-icon="passwordShowConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="passwordShowConfirm = !passwordShowConfirm">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialog=!dialog">閉じる</v-btn>
          <v-spacer />
          <v-btn class="primary" @click="update()">変更</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "AccountMenu",
  data: () => ({
    menuSelect: [{key:"changePass", name:"パスワード変更"},{key:"logout", name:"ログアウト"}],
    menuSelected: null,
    accountid: "",
    accountName: "",
    loginFunctionIdList: [],
    nowPassword: "",
    password: "",
    confirm: "",
    dialog: false,
    isLoading: false,
    fullPage: true,
    passwordShowNow: false,
    passwordShow: false,
    passwordShowConfirm: false,
    adLogin: false,
    opid: "",
  }),
  components: {
    loading
  },
  mounted() {
    var loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
    // 未ログインチェック
    if(loginInfo === null){
      //未ログイン時のログイン画面への遷移はmenulistの方でやるので、重複しないようここではやらない
      return;
    }
    // 有効期限チェック
    var limitDate = new Date(loginInfo.limit);
    var now = new Date();
    if(limitDate < now){
      //未ログイン時のログイン画面への遷移はmenulistの方でやるので、重複しないようここではやらない
      return;
    }

    this.accountid = loginInfo.accountid;
    this.accountName = loginInfo.accountname;
    this.adLogin = loginInfo.adLogin;
    this.opid = loginInfo.opid;
    this.loginFunctionIdList = loginInfo.functionid;
  },
  methods: {
    selectItem(item){
      if (item.key === "logout") { 
        localStorage.setItem("loginInfo", null);
        this.$router.push('/login')
      } else if (item.key === "changePass") { 
        this.dialog = true;
      }
    },
    async update(){
      if(!this.password) {
        alert("パスワードが入力されていません。");
      } else if(this.password !== this.confirm) {
        alert("パスワードと確認が一致していません。");
      } else {
        if(window.confirm('パスワードを変更してよいですか？')){
          this.isLoading = true;
          const headers = {
            "Content-Type": "application/json",
          };
          const body = {
              id: this.accountid,
              nowPassword: this.nowPassword,
              password: this.password
          };
          //パスワード変更
          await this.axios
            .post(`${process.env.VUE_APP_API_END_POINT}/changepass`, body, headers)
            .then((response) => {
              if (response.data.result !== 'OK') {
                this.isLoading = false;
                this.dialog = false;
                alert(response.data.msg);
                return;
              }
              alert("パスワードを変更しました。");
              this.isLoading = false;
              this.dialog = false;
              this.nowPassword = "";
              this.password = "";
              this.confirm = "";
            })
            .catch((e) => {
              this.isLoading = false;
              this.dialog = false;
              alert(JSON.parse(e.response.data.errorMessage).errorMessage);
            });
        }
      }
    },
    onCancel() {
      console.info("User cancelled the loader.");
    },
  }
};
</script>
<style>
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
</style>